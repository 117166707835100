import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Form from "../components/Form";

const awakeAtNight = [
  "When can I stop working?",
  "How long will my money last?",
  "How do I get a retirement paycheck?",
  "Should I payoff my mortgage?",
  "How do I pay for my kid’s education?",
  "Do I need more, less or difference insurance?",
  "When should I start to collect social security?",
  "Are my investments properly diversified or should I move them all to cash?",
  "How can I reduce my tax liability?",
  "Should I do a Roth conversion?",
  "Help me best utilize my employer benefits?",
  "How do I figure out Medicare benefits?",
  "Do I need a will or a trust?",
  "What’s the best way to provide an inheritance for my children/grandchildren?",
  "Too many questions, not all are listed...",
  "All of the above...",
];

const grossMonthlyIncome = [
  "< $2,500",
  "$2,501 - $5,000",
  "$5,001 - $7,500",
  "$7,501 - $10,000",
  "> $10,000",
];

const leftOver = [
  "Provide more charitable gifts",
  "Save it",
  "Spend it",
  "Pay down debt",
  "Don’t know where it goes",
];

const knowledgeLevel = [
  "I do not know the difference between a stock and a bond",
  "I know the basics about stocks, bonds and mutual funds",
  "I am quite knowledgeable about various types of investments",
];

const howSaving = [
  "I and/or spouse have individual retirement accounts",
  "I and/or spouse have employer sponsored retirement plans (401k, 403b, 457, etc)",
  "I and/or spouse have a pension plan",
  "I and/or spouse have purchased an annuity",
  "I and/or spouse will be totally relying on social security",
  "Other:",
];

const retirementSpending = [
  "none",
  "<250,000",
  "$250,001 - $500,000",
  "$500,001 - $1,000,000",
  "$1,000,001 - $2,000,000",
  ">$2,000,001",
];

const othersSpending = [
  "none",
  "<250,000",
  "$250,001 - $500,000",
  "$500,001 - $1,000,000",
  "$1,000,001 - $2,000,000",
  ">$2,000,001",
];

const riskLevel = [
  "Do you have sufficient medical and dental coverage?",
  "Do you have life insurance?",
  "Are you worried about long term care costs?",
  "Are you comfortable with investment volatility?",
];

const incomePercentageTax = [
  "Less than 20%",
  "More than 20% but less than 30%",
  "More than 30%",
  "Unsure",
];

const doInRetirement = [
  "Travel",
  "Visit family/kids",
  "Volunteer",
  "Sleep",
  "Hobbies such as golf/quilting/gardening, etc",
  "Other:",
];

const RetireeQuestionnaire = ({ data }) => {
  const siteTitle = "Retiree - Getting Started";
  const siteDescription = data.site.siteMetadata.description;

  return (
    <Layout location={data.site.siteMetadata.location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDescription} />

      <div className="questionnaire-section">
        <div className="container">
          <h1 className="section-h text-center text-white">
            <span
              className="d-block text-uppercase"
              style={{ fontSize: "24px" }}
            >
              Getting Started
            </span>
            <strong>Retiree</strong>
          </h1>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="panel p-4 p-md-5" style={{ zIndex: 1 }}>
                <p className="small text-muted font-italic">
                  <span className="text-danger">*</span> indicates a required
                  field.
                </p>
                <Form
                  form_name="Retiree Getting Started Questionnaire"
                  form_id="c4b20e94-e8ec-4681-a99e-1de9877101bc"
                  form_classname="regular-form"
                  thankyou_url="/form-submitted"
                  method="post"
                >
                  <div className="form-section">
                    <h2 className="h4 mb-4">Who are you?</h2>
                    <div className="row justify-content-start">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="firstName">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="First Name"
                            id="firstName"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="lastName">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Last Name"
                            id="lastName"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label htmlFor="age">
                            Age <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Age"
                            id="age"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="phone">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="tel"
                            name="Phone"
                            id="phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="email">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            name="Email"
                            id="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group d-flex align-items-center">
                          <label htmlFor="married" className="w-auto">
                            Are you married?
                          </label>
                          <div className="form-check ml-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Married?"
                              id="yes_married"
                              value="Yes"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="yes_married"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check ml-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Married?"
                              id="no_married"
                              value="No"
                            />
                            <label
                              className="form-check-input"
                              htmlFor="no_married"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3">If yes:</div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="spouseName">Spouse's Full Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="Spouse's Full Name"
                            id="spouseName"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="spouseAge">Spouse's Age</label>
                          <input
                            className="form-control"
                            type="text"
                            name="Spouse's Age"
                            id="spouseAge"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group d-flex align-items-center">
                          <label htmlFor="children" className="w-auto">
                            Do you have children?
                          </label>
                          <div className="form-check ml-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Children?"
                              id="yes_children"
                              value="Yes"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="yes_children"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check ml-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Children?"
                              id="no_children"
                              value="No"
                            />
                            <label
                              className="form-check-input"
                              htmlFor="no_children"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3">If yes:</div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="noOfChildren">No. of Children</label>
                          <input
                            className="form-control"
                            type="text"
                            name="No. of Children"
                            id="noOfChildren"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="childrenAge">Ages of Children</label>
                          <input
                            className="form-control"
                            type="text"
                            name="Ages of Children"
                            id="childrenAge"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-group">
                      <h2 className="h4 mb-4">
                        What is keeping you awake at night?
                      </h2>
                      <span className="d-block mb-2">
                        <label htmlFor="topics" className="w-auto">
                          Select all that you have questions on:
                        </label>
                      </span>
                      {awakeAtNight.map((topic, i) => (
                        <div className="form-check ml-2" key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="What is keeping you awake at night?"
                            id={`awakeAtNight` + i}
                            value={topic}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`awakeAtNight` + i}
                          >
                            {topic}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-section">
                    <h2 className="h4 mb-4">Where are you today?</h2>
                    <h3 className="h6 font-weight-bold my-3">Budget NOW</h3>
                    <div className="row justify-content-start pl-3">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="grossMonthlyIncome">
                            Your gross monthly income (
                            <strong>
                              <u>before</u>
                            </strong>{" "}
                            anything is withheld)
                          </label>
                          {grossMonthlyIncome.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Your gross monthly income"
                                id={`grossMonthlyIncome` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`grossMonthlyIncome` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="spouseGrossMonthlyIncome">
                            Your spouse's gross monthly income
                          </label>
                          {grossMonthlyIncome.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Your spouse's gross monthly income"
                                id={`spouseGrossMonthlyIncome` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`spouseGrossMonthlyIncome` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="monthlyMortgage">
                            Monthly mortgage
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Monthly mortgage"
                            id="monthlyMortgage"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="debtPayments">
                            Other debt payments
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Other debt payments"
                            id="debtPayments"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="livingExpenses">
                            Other living expenses
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Other living expenses"
                            id="livingExpenses"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="monthlyExpenses">
                            Total monthly expenses
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Total monthly expenses"
                            id="monthlyExpenses"
                          />
                          <small
                            className="text-muted d-block"
                            style={{ lineHeight: 1.25 }}
                          >
                            (calculated from adding monthly mortgage + debt
                            payments + other living expenses)
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="monthlySavings">
                            Total monthly savings
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="Total monthly savings"
                            id="monthlySavings"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="monthlyLeft">
                            How much is left at the end of an average month?
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="How much is left at the end of an average month?"
                            id="monthlyLeft"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="leftOver" className="w-auto">
                            What do you do with what's left over?
                          </label>
                          {leftOver.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="What do you do with what's left over?"
                                id={`leftOver` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`leftOver` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <h3 className="h6 font-weight-bold my-3">
                      Budget IN RETIREMENT
                    </h3>
                    <div className="row justify-content-start pl-3">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="moreLessIncome" className="w-auto">
                            Will you have more income or less income?
                          </label>
                          <div className="d-flex">
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Will you have more income or less income?"
                                id="more_moreLessIncome"
                                value="More"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="more_moreLessIncome"
                              >
                                More
                              </label>
                            </div>
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Will you have more income or less income?"
                                id="less_moreLessIncome"
                                value="Less"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="less_moreLessIncome"
                              >
                                Less
                              </label>
                            </div>
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Will you have more income or less income?"
                                id="idk_moreLessIncome"
                                value="I don't know"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="idk_moreLessIncome"
                              >
                                I don't know
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="moreLessExpense" className="w-auto">
                            More or less expense?
                          </label>
                          <div className="d-flex">
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="More or less expense?"
                                id="more_moreLessExpense"
                                value="More"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="more_moreLessExpense"
                              >
                                More
                              </label>
                            </div>
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="More or less expense?"
                                id="less_moreLessExpense"
                                value="Less"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="less_moreLessExpense"
                              >
                                Less
                              </label>
                            </div>
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="More or less expense?"
                                id="idk_moreLessExpense"
                                value="I don't know"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="idk_moreLessExpense"
                              >
                                I don't know
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-start mt-3">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="knowledgeLevel">
                            Your level of investment knowledge
                          </label>
                          {knowledgeLevel.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Your level of investment knowledge"
                                id={`knowledgeLevel` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`knowledgeLevel` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="howSaving">
                            How have you been saving for retirement and/or other
                            goals? (Check all that apply.)
                          </label>
                          {howSaving.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="How have you been saving for retirement and/or other
                              goals?"
                                id={`howSaving` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`howSaving` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}

                          <div className="row ml-3">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                name="How have you been saving for retirement and/or other goals?"
                                id="howSaving"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="retirementSpending">
                            Total Amount saved specifically for retirement
                            spending
                          </label>
                          {retirementSpending.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Total Amount saved specifically for retirement spending"
                                id={`retirementSpending` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`retirementSpending` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="othersSpending">
                            Total amounts saved for other things such as "start
                            a business, inheritance, buy a second home,
                            emergency, etc"
                          </label>
                          {othersSpending.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Total amounts saved for other things such as 'start a business, inheritance, buy a second home,
                                emergency, etc'"
                                id={`othersSpending` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`othersSpending` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <h3 className="h6 font-weight-bold my-3">
                          Levels of risk you are willing to take (or not)
                        </h3>
                        <div className="form-group pl-3">
                          {riskLevel.map((question, i) => (
                            <div className="form-group">
                              <label
                                htmlFor={`riskLevel` + i}
                                className="w-auto"
                              >
                                {question}
                              </label>
                              <div className="d-flex">
                                <div className="form-check ml-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={question}
                                    id={`yes_riskLevel` + i}
                                    value="Yes"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`yes_riskLevel` + i}
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={question}
                                    id={`no_riskLevel` + i}
                                    value="No"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`no_riskLevel` + i}
                                  >
                                    No
                                  </label>
                                </div>
                                <div className="form-check ml-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={question}
                                    id={`idk_riskLevel` + i}
                                    value="I don't know"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`idk_riskLevel` + i}
                                  >
                                    I don't know
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="incomePercentageTax"
                            className="w-auto"
                          >
                            What % of your income is going to Federal income
                            taxes, State income taxes and FICA?
                          </label>
                          {incomePercentageTax.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="What % of your income is going to Federal income
                                taxes, State income taxes and FICA?"
                                id={`incomePercentageTax` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`incomePercentageTax` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="doInRetirement" className="w-auto">
                            What do you plan to do in retirement? (Check all
                            that apply.)
                          </label>
                          {doInRetirement.map((choice, i) => (
                            <div className="form-check ml-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="What do you plan to do in retirement?"
                                id={`doInRetirement` + i}
                                value={choice}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`doInRetirement` + i}
                              >
                                {choice}
                              </label>
                            </div>
                          ))}

                          <div className="row ml-3">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                type="text"
                                name="What do you plan to do in retirement?"
                                id="doInRetirement"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="whereGoodbye">
                            Where will your stuff (valuables) go when you’ve
                            said your last good-byes?
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="Where will your stuff (valuables) go when you've
                            said your last good-byes?"
                            id="whereGoodbye"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12  d-flex justify-content-center">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>

                  <div className="col-12  d-flex justify-content-center">
                    <button
                      className="bttn m-2"
                      type="submit"
                      style={{ fontSize: "14px" }}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default RetireeQuestionnaire;

export const RetireeQuestionnaireQuery = graphql`
  query RetireeQuestionnaireQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`;
